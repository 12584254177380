import React, { FC } from "react";
import DogCover from "../../../../../public/images/dog-cover.png";
import { Trans } from "translations";
import { LinkT, useWebTranslation } from "../../../../translations";
import CatCover from "../../../../../public/images/cat-cover.png";
import Image from "next/image";
import ResponsiveGutter from "../../../../ui-kit/ResponsiveGutter";
import Gutter from "../../../../ui-kit/Gutter";
import { CompactInfoSection } from "../../../CompactInfoSection/CompactInfoSection";
import styles from "./HelpYouHelpBlock.module.css";
import { FlexRow } from "../../../../ui-kit/Layout/Flex";
import { Typography } from "../../../../ui-kit/Typography";

type Props = {
  species: "dog" | "cat";
};

export const HelpYouHelpBlock: FC<Props> = ({ species }) => {
  const { t } = useWebTranslation();
  const dogData = {
    title: t("dog_insurance.info_section.title"),
    imgSrc: DogCover,
    imgAlt: t("dog_insurance.info_section.alt"),
    checkmarks: [
      t("dog_insurance.info_section.checkmarks.0"),
      t("dog_insurance.info_section.checkmarks.1"),
      t("dog_insurance.info_section.checkmarks.2"),
      <span key={99}>
        <Trans
          i18nKey="dog_insurance.info_section.checkmarks.3"
          components={{
            1: <LinkT href="/lassieapp" />,
          }}
        />
      </span>,
    ],
  };

  const catData = {
    title: t("cat_insurance.info_section.title"),
    imgSrc: CatCover,
    imgAlt: t("cat_insurance.info_section.alt"),
    checkmarks: [
      t("cat_insurance.info_section.checkmarks.0"),
      t("cat_insurance.info_section.checkmarks.1"),
      t("cat_insurance.info_section.checkmarks.2"),
      <span key={99}>
        <Trans
          i18nKey="cat_insurance.info_section.checkmarks.3"
          components={{
            1: <LinkT href="/lassieapp" />,
          }}
        />
      </span>,
    ],
  };

  const data = species === "cat" ? catData : dogData;

  return (
    <>
      <ResponsiveGutter desktop={16} tablet={5.5} mobile={5.5} />
      <CompactInfoSection
        mobileCard
        mobileDirection={"reverse"}
        alignItems={"flex-start"}
      >
        <div>
          <Image
            src={data.imgSrc}
            alt={data.imgAlt}
            loading="lazy"
            width={480}
            height={315}
            sizes="100vw"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ResponsiveGutter mobile={1} tablet={1} />
          <Typography variant="h5">{data.title}</Typography>
          <Gutter size={2} />
          <div className={styles.checkpoints}>
            {data.checkmarks.map((item, index) => (
              <>
                <FlexRow alignItems="flex-start" key={index.toString()}>
                  <Image
                    className={styles.checkPoint}
                    src="/images/check-badge.png"
                    alt="check-badge"
                    width={24}
                    height={24}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  {item}
                </FlexRow>
                <Gutter size={2} />
              </>
            ))}
          </div>
        </div>
      </CompactInfoSection>
    </>
  );
};
