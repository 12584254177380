import Link from "next/link";
import React, { CSSProperties, FC } from "react";
import { Trans } from "translations";

import styles from "./index.module.css";
import {
  prepurchaseInfoInsurance,
  prepurchaseInfoLassie,
  termsAndConditions,
} from "../../../../components-shared/insuranceData";
import classNames from "classnames";
import { useRouter } from "next/router";
import Gutter from "../../../../ui-kit/Gutter";
import Image from "next/image";

type variant = "sweden" | "germanShort" | "germanFull";

type Item = {
  link: string;
  icon: string;
  text: string;
};

type Props = {
  style?: CSSProperties;
  variant?: variant;
  en: Item[];
  de: Item[];
};

const iconMap: Record<string, string> = {
  shieldHeart: "/images/insurance/shield-heart.svg",
  creditCard: "/images/insurance/credit-card.svg",
  documentCheck: "/images/insurance/document-check.svg",
  dogPhone: "/images/insurance/dog-phone.svg",
  phone: "/images/insurance/device-phone.svg",
  chat: "/images/insurance/message-bubble.svg",
  checkBadge: "/images/insurance/check-badge.svg",
  learn: "/images/insurance/learn.svg",
  firstPrize: "/images/insurance/first-prize.svg",
  dogJump: "/images/insurance/dog-jump.svg",
  dogHealth: "/images/insurance/dog-health.svg",
  discountBadge: "/images/insurance/discount-badge.svg",
  healthHands: "/images/insurance/health-hands.svg",
  vetMeeting: "/images/insurance/vet-meeting.svg",
  catProfileMultiple: "/images/insurance/cat-profile-multiple.svg",
  shieldTravel: "/images/insurance/shield-travel.svg",
  pawCat: "/images/insurance/paw-cat.svg",
};

const defaultItems: Item[] = [
  {
    text: "information.insurance",
    icon: "shieldHeart",
    link: termsAndConditions,
  },
  {
    text: "information.purchase",
    icon: "creditCard",
    link: prepurchaseInfoInsurance,
  },
  {
    text: "information.lassie",
    icon: "documentCheck",
    link: prepurchaseInfoLassie,
  },
];

const Information: FC<Props> = ({ style, de, en }) => {
  const router = useRouter();
  const items = (de && router.locale === "de" ? de : en) ?? defaultItems;

  return (
    <article style={style} className={styles.usefulInformation}>
      <section className={styles.advices}>
        {items.map(({ link, icon, text }, idx) =>
          link ? (
            <Link key={idx} href={link} className={classNames(styles.advice)}>
              <div className={styles.iconContainer}>
                <Image
                  width={75}
                  height={75}
                  className={styles.icon}
                  src={iconMap[icon] ?? ""}
                  alt={icon}
                />
              </div>
              <Gutter size={1} />
              <p>
                <Trans i18nKey={text} />
              </p>
            </Link>
          ) : (
            <div key={idx} className={classNames(styles.advice)}>
              <div className={styles.iconContainer}>
                <Image
                  width={75}
                  height={75}
                  src={iconMap[icon] ?? ""}
                  alt={icon}
                />
              </div>
              <Gutter size={1} />
              <p>
                <Trans i18nKey={text} />
              </p>
            </div>
          )
        )}
      </section>
    </article>
  );
};

export default Information;
