import React, { ReactElement } from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import { Button } from "../../../ui-kit/Button";
import { Typography } from "../../../ui-kit/Typography";
import Gutter from "../../../ui-kit/Gutter";

import { speciesToEmoji } from "../../../utils/insurance";
import { useWebTranslation } from "../../../translations";
import { logEvent } from "../../../analytics";

import styles from "./StandardHero.module.css";
import { replaceCommonMarkup } from "utils/utils";
import { Asset } from "components-shared/ContentfulMedia";
import { Hero_c2A } from "../../../../__generated__/Hero";
import { ContentfulC2A } from "../ContentfulC2A";
import { ReviewsSection } from "./ReviewsSection";
import { ReferralSection } from "./ReferralSection";
import { useResumePreviewStore } from "./useResumePreviewStore";

import dynamic from "next/dynamic";
import ShowBlockOnMedia from "ui-kit/ShowBlockOnMedia";
import ShowFlexOnMedia from "ui-kit/ShowFlexOnMedia";
import { isOrg } from "utils/site";
import classNames from "classnames";

const DynamicVideo = dynamic(() => import("./HeroVideo"), {
  ssr: false,
});

type HeroProps = {
  title: string | null;
  text?: string | null;
  disclaimer?: string | null;
  usps?: string[];
  media: Asset;
  c2a?: Hero_c2A | null;
  useVideo: boolean;
  thumbnail: Asset;
};

export default function Hero({
  title,
  disclaimer,
  text,
  usps,
  media,
  thumbnail,
  c2a,
  useVideo,
}: HeroProps): ReactElement {
  const { t } = useWebTranslation();
  const router = useRouter();
  const signupState = useResumePreviewStore();

  return (
    <>
      <div className={styles.container}>
        {useVideo ? (
          <DynamicVideo />
        ) : (
          <>
            <ShowFlexOnMedia
              className={styles.mediaContainer}
              mobile={false}
              tablet={false}
              desktop
            >
              {media && (
                <Image
                  src={media.url ?? ""}
                  alt={media.title ?? media.description ?? ""}
                  width={media.width ?? 1200}
                  height={media.height ?? 1200}
                  className={styles.media}
                />
              )}
            </ShowFlexOnMedia>
            <ShowBlockOnMedia
              className={styles.mediaContainer}
              mobile
              tablet
              desktop={false}
            >
              {thumbnail ? (
                <Image
                  src={thumbnail.url ?? ""}
                  alt={thumbnail.title ?? thumbnail.description ?? ""}
                  width={thumbnail.width ?? 1200}
                  height={thumbnail.height ?? 1200}
                  className={styles.media}
                />
              ) : null}
            </ShowBlockOnMedia>
          </>
        )}

        <div
          className={classNames(
            styles.imageBadge,
            isOrg("ger") && styles.tuvBadges
          )}
        >
          <Image
            src="/images/hero-avatars.png"
            alt=""
            width={82}
            height={30}
            className={styles.avatars}
          />
          <div className={styles.badgeText}>
            <Typography variant="h3">
              <strong>{t("hero.pets_number")}</strong>
            </Typography>
            <Typography variant="body2" style={{ lineHeight: 1 }}>
              {t("hero.pets_suffix")}
            </Typography>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.textContent}>
            <div className={styles.title}>
              <Typography variant="h1">{title}</Typography>
            </div>
            {text && (
              <Typography
                variant="body2"
                className={styles.description}
                color="grey500"
              >
                {text}
              </Typography>
            )}
            {usps && (
              <div className={styles.usps}>
                <ul className={styles.uspList}>
                  {usps.map((usp) => (
                    <li className={styles.uspListItem} key={usp}>
                      <Typography color="black">
                        {replaceCommonMarkup(usp)}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <ReviewsSection />
            <div
              style={{
                maxHeight: "100vh",
              }}
            >
              <div className={styles.button}>
                {c2a ? (
                  <span onClick={() => logEvent("USER_HOMEPAGE_GET_QUOTE")}>
                    <ContentfulC2A {...c2a} />
                  </span>
                ) : null}
                {signupState?.previewId && (
                  <>
                    <Gutter size={2} />
                    <div className={styles.resumeQuoteButton}>
                      <Button
                        textStyle={{
                          fontSize: 16,
                          fontFamily: "var(--font-eina-bold)",
                        }}
                        variant={"custom"}
                        color={"white"}
                        textColor="blue300"
                        title={t("home.sections.0.btn_resume_quote", {
                          name: signupState?.name ?? "?",
                          emoji: speciesToEmoji(signupState.species),
                        })}
                        onPress={() => {
                          const isLiability = signupState.isLiability;

                          logEvent("USER_HOMEPAGE_RESUME_QUOTE");
                          router.push(
                            `/signup/${
                              isLiability
                                ? "liability-quote-preview"
                                : "quote-preview"
                            }?id=${signupState?.previewId}`
                          );
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {disclaimer ? (
              <Typography
                variant="body4"
                className={styles.disclaimer}
                color="grey500"
                component="div"
              >
                {disclaimer}
              </Typography>
            ) : null}
          </div>
        </div>
      </div>
      <ReferralSection />
    </>
  );
}
