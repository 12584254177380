import Image from "next/image";
import { createElement, useEffect, useMemo, useRef } from "react";
import { getOrg, Org } from "utils/site";
import styles from "./ReviewsSection.module.css";
import { AppStores } from "./ReviewsSection/Appstores";
import Link from "next/link";
import { contentfulLocale } from "utils/utils";
import { i18n, useWebTranslation } from "translations";

const TrustPilotFr = () => {
  const { i18n } = useWebTranslation();

  const ref = useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    // @ts-ignore
    if (window.Trustpilot) {
      // @ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div className={styles.review}>
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale={contentfulLocale(i18n?.language ?? "en_GB")}
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="60dda23d8223de0001953209"
        data-style-height="40px"
        data-style-width="100%"
        data-theme="light"
      />
    </div>
  );
};

const TrustPilotDe = () => {
  return (
    <Link href="https://de.trustpilot.com/review/lassie.co" target="_blank">
      <Image
        src={`/images/hero/trustpilot-${i18n?.language}.svg`}
        alt=""
        width={112}
        height={65}
      />
    </Link>
  );
};

const Konsumenternas = () => {
  return (
    <Image
      src="/images/hero/konsumenternas.svg"
      alt=""
      width={114}
      height={42}
    />
  );
};

const Ski = () => {
  return <Image src="/images/hero/ski.png" alt="" width={177} height={35} />;
};

const Tuv = () => {
  return (
    <Link href="https://www.tuev-saar.de/SC46160" target="_blank">
      <Image src="/images/tuv/tuv-plv.svg" height={65} width={122} alt="" />
    </Link>
  );
};

const reviewWidgets = {
  konsumenternas: Konsumenternas,
  appStores: AppStores,
  trustpilotde: TrustPilotDe,
  trustpilotfr: TrustPilotFr,
  ski: Ski,
  tuv: Tuv,
};

type ReviewWidgets = keyof typeof reviewWidgets;

const getItemsForOrg = (org: Org): ReviewWidgets[] => {
  switch (org) {
    case "ger":
      return ["trustpilotde", "tuv"];
    case "fra":
      return ["trustpilotfr"];
    case "swe":
      return ["ski", "konsumenternas"];
    default:
      return [];
  }
};

export const ReviewsSection = () => {
  const items = useMemo(() => getItemsForOrg(getOrg()), []);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={styles.reviewsSection}>
      {items.map((item, index) =>
        createElement(reviewWidgets[item], { key: item + index })
      )}
    </div>
  );
};
