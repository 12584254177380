import React, { FC } from "react";
import styles from "./TassfondenBlock.module.css";
import Gutter from "../../../../ui-kit/Gutter";
import { Typography } from "../../../../ui-kit/Typography";
import Image from "next/image";
import TassfondenLogo from "../../../../assets/images/tassfonden-logo.png";
import TassfondenQr from "../../../../assets/images/tassfonden-qr.png";
import { Trans, useWebTranslation } from "translations";
import Colors from "../../../../ui-kit/colors";

const PhoneT = (props: any) => {
  return (
    <span
      style={{ color: Colors.blue300, fontFamily: "var(--font-eina-bold)" }}
    >
      {props.children}
    </span>
  );
};

const TextT = (props: any) => {
  return (
    <section style={{ marginBottom: 24 }}>
      <span>{props.children}</span>
    </section>
  );
};

export const TassfondenBlock: FC = () => {
  const { t } = useWebTranslation();
  return (
    <div className={styles.container}>
      <div style={{ flex: 2 }}>
        <Gutter size={4} />
        <Typography variant="h2" component={"div"}>
          {t("fund.section_1.title")}
        </Typography>
        <Gutter size={2} />
        <Typography color={"grey500"} component={"div"}>
          <Trans
            i18nKey="fund.section_1.text"
            components={{
              text: <TextT />,
            }}
          />{" "}
        </Typography>
        <div className={styles.logo}>
          <Image
            src={TassfondenLogo}
            alt="Tassfonden logo"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      </div>
      <div className={styles.card}>
        <Image
          src={TassfondenQr}
          className={styles.infoCardImage}
          alt="Tassfonden QR"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
        <div className={styles.cardText}>
          <Typography>
            <Trans
              i18nKey="fund.section_1.image_text"
              components={{
                phone: <PhoneT />,
              }}
            />
          </Typography>
        </div>
      </div>
    </div>
  );
};
